import {create} from 'zustand';
import {persist} from 'zustand/middleware';


export const useMarkPrice = create (set => ({
  markPrice: 0,
  setMarkPrice: markPrice => set (() => ({markPrice})),
}));

export const useUsdEthPrice = create (set => ({
  usdEthPrice: 0,
  setUsdEthPrice: usdEthPrice => set (() => ({usdEthPrice})),
}));

export const useSkip = create (set => ({
  skip: 0,
  setSkipVar: skip => set (() => ({skip})),
}));

export const useSelectedPage = create (set => ({
  selectedPage: 0,
  setSelectedPage: selectedPage => set (() => ({selectedPage})),
}));

export const useItemsPage = create (set => ({
  itemsPerPage: 10,
  setItemsPerPage: itemsPerPage => set (() => ({itemsPerPage})),
}));

export const useActiveTab = create (set => ({
  activeTab: 'liquidatable',
  setActiveTab: activeTab => set (() => ({activeTab})),
}));

export const useOrder = create (set => ({
  direction: 'asc', //DESC, if 0 then it is ASC
  setOrder: direction => set (() => ({direction})),
}));

export const useSelectedMarket = create (set => ({
  selectedMarket: localStorage.getItem ('selectedMarket'),
  setSelectedMarket: selectedMarket => set (() => ({selectedMarket})),
}));
