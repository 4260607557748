// @link - https://dev.to/gabrielmlinassi/a-more-stylish-way-to-write-conditional-tailwind-classes-5ae6

import React from 'react';
import clsx from 'clsx';
import { loaderSizeStyle, sizeStyle, variantStyle } from './constants';
import { ButtonProps } from './definitions';
import { BeatLoader } from 'components/Loaders';

/**
 * Button component
 */
const Button: React.FC<ButtonProps> = ({
    size = 'md',
    colorScheme = 'primary',
    variants = 'default',
    isLoading,
    isFullWidth,
    className,
    children,
    ...props
}) => {
    return (
        <>
            {variants === 'colouredDisable' ? (
                <button
                    className={clsx(
                        `
          rounded-sm 
          inline-flex 
          items-center 
          justify-center
          disabled:bg-gradient-to-br from-[#5C03BC] to-[#E536AB]
          disabled:text-white
          disabled:cursor-not-allowed`,
                        variantStyle[variants][colorScheme],
                        sizeStyle[size],
                        isFullWidth && 'w-full',
                        className
                    )}
                    {...props}
                >
                    {isLoading ? <BeatLoader size={loaderSizeStyle[size]} /> : <>{children}</>}
                </button>
            ) : (
                <button
                    className={clsx(
                        `
          rounded-sm
          inline-flex 
          items-center 
          justify-center
          disabled:!bg-none
          disabled:border-disabledButtonBorder
          disabled:bg-disabledButtonBG
          disabled:text-textColor-disabledButtonText
          transform transition-transform duration-150 ease-in-out active:scale-95
          disabled:cursor-not-allowed`,
                        variantStyle[variants][colorScheme],
                        sizeStyle[size],
                        isFullWidth && 'w-full',
                        className
                    )}
                    {...props}
                >
                    {isLoading ? <BeatLoader size={loaderSizeStyle[size]} /> : <>{children}</>}
                </button>
            )}
        </>
    );
};

export default Button;
export * from './definitions';
