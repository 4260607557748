import React from 'react';
import { useSelectedMarket } from 'store';

const LabelWethIcon = () => {
    const { selectedMarket } = useSelectedMarket();

    return (
        <span className="ml-[6px] flex items-center tracking-wider text-[10px] font-normal font-inter h-[16px] bg-snvBackground text-textColor-snvText px-[4px] py-[1px] rounded-sm">
            {selectedMarket === 'snv' ? 'WETH' : 'USD'}
        </span>
    );
};

export default LabelWethIcon;
