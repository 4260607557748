import React, { useState, useEffect } from 'react';
import LoadingSkeleton from 'components/Animation/LoadingSkeleton';
import LabelWethIcon from 'components/Common/Units/LabelWethIcon';
import LabelSnvIcon from 'components/Common/Units/LabelSnvIcon';
import { IoCopyOutline } from 'react-icons/io5';
import { useMarkPrice, useUsdEthPrice, useSelectedMarket } from 'store';
import Button from 'components/Common/Button/Button';
import { useAccount, useContractWrite, useWaitForTransaction } from 'wagmi';
import { TransactionExecutionError } from 'viem';
import { useToastContext, NotificationActions } from 'context/NotificationContext/NotificationContext';
import { MessageHeader, getNotificationMessages } from 'utils';
import { CHAIN_ID, CONTRACT_ADDRESSES } from 'config/constants';
import { Margin } from 'assets/abi';
import { CustomTooltip } from 'components/Common';

interface LeaderboardTableRowProps {
    item: any;
    loading: boolean;
}

const LeaderboardTableRow: React.FC<LeaderboardTableRowProps> = ({ item, loading }) => {
    const [tooltipTitle, setTooltipTitle] = useState('Copy address');
    const [liquidateHash, setLiquidateHash] = useState<`0x${string}` | undefined>(undefined);
    const toastContext = useToastContext();
    const notificationMessage = getNotificationMessages(MessageHeader.LIQUIDATE);
    const { selectedMarket } = useSelectedMarket();
    const LIQUIDATE_CONTRACT_ADDRESS = CONTRACT_ADDRESSES[CHAIN_ID][selectedMarket].Margin;
    const { usdEthPrice } = useUsdEthPrice();
    const { markPrice } = useMarkPrice();
    const [liqStatus, setLiquidateAcctStatus] = useState(false);
    const { address } = useAccount();

    const handleCopyAddress = (useraddr: string) => {
        navigator.clipboard.writeText(useraddr);
        setTooltipTitle('Copied');
        setTimeout(() => setTooltipTitle('Copy address'), 2000);
    };

    // const { data: liquidateStatus, isLoading: isCanLiquidateLoading, refetch: refetchCanLiquidate } = useContractRead({
    //     address: LIQUIDATE_CONTRACT_ADDRESS,
    //     functionName: 'canLiquidate',
    //     abi: Margin,
    //     args: address && isConnected && item?.user ? [address as `0x${string}`, item.user] : undefined,
    //     enabled: !!address && isConnected && !!item?.user,
    //     staleTime: 40_000,
    //     cacheTime: 90_000
    // });
    // console.log("Contract call parameters:",{
    //     address: LIQUIDATE_CONTRACT_ADDRESS,
    //     functionName: 'canLiquidate',
    //     args: address && isConnected && item?.user ? [address as `0x${string}`, item.user] : undefined,
    //     enabled: !!address && isConnected && !!item?.user,
    //   })

    //   console.log("liquidateStatus", liquidateStatus)
    //   console.log("isCanLiquidateLoading", isCanLiquidateLoading)
    // const checkAndRefetch = useCallback(async () => {
    //     if (address && isConnected && refetchCanLiquidate && !isCanLiquidateLoading && item?.currentMR !== undefined) {
    //         if (item.currentMR > 0 && item.currentMR < 500) {
    //             try {
    //                 await refetchCanLiquidate();
    //             } catch (error) {
    //                 console.error('Error refetching canLiquidate:', error);
    //             }
    //         }
    //     }
    // }, [address, isConnected, refetchCanLiquidate, isCanLiquidateLoading, item]);

    // useEffect(() => {
    //     checkAndRefetch();
    // }, [checkAndRefetch]);

    // useEffect(() => {
    //     setLiquidateAcctStatus(!!liquidateStatus);
    // }, [liquidateStatus]);

    const { data, write } = useContractWrite({
        address: LIQUIDATE_CONTRACT_ADDRESS,
        functionName: 'liquidate',
        abi: Margin,
        args: address && item?.user ? [address as `0x${string}`, item?.user] : undefined,
        onError(error: any) {
            const err = error as TransactionExecutionError;
            toastContext?.toastDispatch({
                type: NotificationActions.ADD,
                payload: {
                    type: 'Error',
                    messageHead: MessageHeader.LIQUIDATE,
                    content:
                        err.shortMessage === 'User rejected the request.'
                            ? notificationMessage.userRejected
                            : notificationMessage.errorMessage
                }
            });
        },
        onSuccess: async (data: any) => {
            if (data) {
                setLiquidateHash(data?.hash);
            }
        }
    });

    const { isSuccess: isLiquidationSuccess } = useWaitForTransaction({ hash: liquidateHash });

    useEffect(() => {
        if (isLiquidationSuccess) {
            toastContext?.toastDispatch({
                type: NotificationActions.ADD,
                payload: {
                    type: 'Success',
                    messageHead: MessageHeader.LIQUIDATE,
                    content: notificationMessage.successMessage,
                    data: data?.hash,
                    isTransaction: true
                }
            });
        }
    }, [isLiquidationSuccess, data?.hash, toastContext, notificationMessage.successMessage]);

    if (loading) {
        return (
            <tr className="">
                <td colSpan={7} className="py-2 px-4">
                    <LoadingSkeleton />
                </td>
            </tr>
        );
    }

    if (!item) {
        return null;
    }
    useEffect(() => {
        setLiquidateAcctStatus(item.canLiquidate);
    }, [item.canLiquidate]);

    return (
        <div className="grid grid-cols-7 py-[6px] odd:bg-openOrderRowBgOdd even:bg-openOrderRowBgEven">
            <div className="flex justify-end items-center pr-7 text-base">
                <span className="font-normal text-textColor-hightlightedText ">
                    {`${item?.user?.slice(0, 6) || ''}...${item?.user?.slice(-4) || ''}`}
                </span>
                <CustomTooltip title={tooltipTitle}>
                    <span
                        className="cursor-pointer ml-1 flex items-center justify-center"
                        onClick={() => handleCopyAddress(item?.user || '')}
                    >
                        <IoCopyOutline size={14} className="text-textColor-hightlightedText" />
                    </span>
                </CustomTooltip>
            </div>
            <div className="flex justify-end items-center pr-14 text-base">
                <span className="mr-1">{item?.position || 'N/A'}</span>
                <LabelSnvIcon />
            </div>
            <div className="flex justify-center items-center text-base">
                ${((item?.position || 0) * (usdEthPrice || 0) * (markPrice || 0)).toFixed(2)}
            </div>
            <div className="flex justify-center items-center text-base">{item?.side || '-'}</div>
            <div className="flex justify-center items-center text-base">{item?.mr || 0}</div>
            <div className="flex justify-end items-center pr-14 text-base">
                <span>{item?.margin || '0.00'}</span>
                <LabelWethIcon />
            </div>
            <div className="flex justify-center">
                <Button
                    className={`rounded-md flex justify-center items-center px-2 text-[0.75rem] focus:outline-none transition-all duration-300 ${
                        liqStatus
                            ? 'border border-divBorder cursor-pointer'
                            : 'border border-disabledButtonBorder cursor-not-allowed'
                    }`}
                    variants="none"
                    disabled={!liqStatus}
                    onClick={() => {
                        if (liqStatus && write) {
                            write();
                        } else {
                            toastContext?.toastDispatch({
                                type: NotificationActions.ADD,
                                payload: {
                                    type: 'Error',
                                    messageHead: MessageHeader.LIQUIDATE,
                                    content: 'Cannot liquidate for mark price > MMF'
                                }
                            });
                        }
                    }}
                >
                    <span
                        className={`font-medium text-base ${
                            liqStatus
                                ? 'text-textColor-hightlightedText'
                                : 'text-textColor-disabledButtonText'
                        }`}
                    >
                        Liquidate
                    </span>
                </Button>
            </div>
        </div>
    );
};

export default LeaderboardTableRow;
