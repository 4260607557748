import { cryptocurrencies } from 'config/constants';
import React, { useState, useEffect, useRef } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { useSelectedMarket } from 'store';
import { BsSearch } from 'react-icons/bs';

interface SearchBarProps {
    onSelect?: (crypto: { id: string; name: string }) => void;
}
const SearchBar: React.FC<SearchBarProps> = ({ onSelect }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCrypto, setSelectedCrypto] = useState<{ id: string; name: string; symbol: string } | null>(
        null
    );
    const [isOpen, setIsOpen] = useState(false);
    const [filteredCryptos, setFilteredCryptos] = useState(cryptocurrencies);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const { setSelectedMarket } = useSelectedMarket();
    useEffect(() => {
        const savedMarket = localStorage.getItem('selectedMarket');
        if (!savedMarket) {
            localStorage.setItem('selectedMarket', 'btc');
            setSelectedMarket('btc');
            const defaultCrypto = cryptocurrencies.find((crypto) => crypto.symbol === 'BTC');
            if (defaultCrypto) setSelectedCrypto(defaultCrypto);
        } else {
            const savedCrypto = cryptocurrencies.find((crypto) => crypto.symbol === savedMarket);
            if (savedCrypto) {
                setSelectedCrypto(savedCrypto);
            } else {
                localStorage.setItem('selectedMarket', 'btc');
                setSelectedMarket('btc');
                const defaultCrypto = cryptocurrencies.find((crypto) => crypto.symbol === 'BTC');
                if (defaultCrypto) setSelectedCrypto(defaultCrypto);
            }
        }
    }, []);
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
        setIsOpen(true);
    };
    const handleCryptoSelect = (crypto: { id: string; name: string; symbol: string }) => {
        localStorage.setItem('selectedMarket', crypto.id);
        setSelectedMarket(crypto.id);
        setSelectedCrypto(crypto);
        setSearchTerm('');
        setIsOpen(false);
        if (onSelect) {
            onSelect(crypto);
        }
    };
    useEffect(() => {
        const filtered = cryptocurrencies.filter(
            (crypto) =>
                crypto.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                crypto.symbol.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredCryptos(filtered);
    }, [searchTerm]);
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
                setSearchTerm('');
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="w-full relative" ref={dropdownRef}>
            <div className="relative flex items-center border border-borderColor">
                <BsSearch className="ml-2 h-[20px] w-[20px] text-textColor-searchMarketText" />
                <input
                    type="search"
                    placeholder={
                        selectedCrypto ? `${selectedCrypto.name} (${selectedCrypto.symbol})` : 'Search market'
                    }
                    value={searchTerm}
                    onChange={handleInputChange}
                    onFocus={() => setIsOpen(true)}
                    className="w-[95%] sm:px-4 py-2 text-sm sm:text-base text-textColor-searchMarketText italic bg-backgroundColor focus:outline-none placeholder-gray-500 backdrop-blur-sm sm:pr-10"
                    aria-label="Search cryptocurrencies"
                    aria-expanded={isOpen}
                    aria-controls="crypto-dropdown"
                />
                <FaChevronDown
                    onClick={toggleDropdown}
                    className="absolute right-2 sm:right-3 top-1/2 cursor-pointer transform -translate-y-1/2 text-borderColor text-sm sm:text-base"
                />
            </div>
            {isOpen && (
                <div
                    id="crypto-dropdown"
                    className="scrollBarDiv absolute w-full mt-2 border border-disabledButtonBorder bg-backgroundColor rounded-sm max-h-60 overflow-auto shadow-xl z-[10000]"
                    role="listbox"
                >
                    {filteredCryptos.length > 0 ? (
                        filteredCryptos.map((crypto) => (
                            <button
                                key={crypto.id}
                                onClick={() => handleCryptoSelect(crypto)}
                                className="w-full text-left px-2 sm:px-4 py-2 text-sm sm:text-base odd:bg-openOrderRowBgOdd even:bg-openOrderRowBgEven hover:bg-tableHoverColor text-textColor-hightlightedText"
                                role="option"
                            >
                                {crypto.name} ({crypto.symbol})
                            </button>
                        ))
                    ) : (
                        <div className="px-2 sm:px-4 py-2 text-sm sm:text-base text-gray-400">
                            No cryptocurrencies found
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
export default SearchBar;
