import React from 'react';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useAccount, useBalance } from 'wagmi';
import { CHAIN_ID, TOKENS_ADDRESSES } from 'config/constants';
import { Button, CustomTooltip, LabelWethIcon } from 'components/Common';
import { interFormat, shortFormat, truncateToTwoDecimalPlaces } from 'utils';
import { IoWalletOutline } from 'react-icons/io5';
interface ConnectWalletProps {
    showName: string;
}
const ConnectWallet = ({ showName }: ConnectWalletProps) => {
    const { address } = useAccount();
    const tokenAddress = TOKENS_ADDRESSES[CHAIN_ID].WETH;
    const { data: balance } = useBalance({
        watch: false,
        address: address,
        token: tokenAddress,
        enabled: !!tokenAddress && !!address,
        staleTime: 50_000,
        cacheTime: 100_0000
    });
    const formattedBalance: number = Number(balance?.formatted) || 0;
    return (
        <ConnectButton.Custom>
            {({
                account,
                chain,
                openAccountModal,
                openChainModal,
                openConnectModal,
                authenticationStatus,
                mounted
            }) => {
                // Note: If your app doesn't use authentication, you
                // can remove all 'authenticationStatus' checks
                const ready = mounted && authenticationStatus !== 'loading';
                const connected =
                    ready &&
                    account &&
                    chain &&
                    (!authenticationStatus || authenticationStatus === 'authenticated');
                return (
                    <div
                        {...(!ready && {
                            'aria-hidden': true,
                            style: {
                                opacity: 0,
                                pointerEvents: 'none',
                                userSelect: 'none'
                            }
                        })}
                    >
                        {(() => {
                            if (!connected) {
                                return (
                                    <button
                                        className="focus:outline-none bg-backgroundColor border border-divBorder px-4 py-[0.2rem] ml-4 font-bold rounded-[2px] text-textColor-hightlightedText"
                                        onClick={openConnectModal}
                                        type="button"
                                    >
                                        Connect Wallet
                                    </button>
                                );
                            }
                            if (chain.id !== 8453 && chain.id !== 84532) {
                                return (
                                    <button
                                        className="text-md bg-arrowColorRed text-textColor-snvText rounded-[2px] px-4 py-1"
                                        onClick={openChainModal}
                                        type="button"
                                    >
                                        Wrong network
                                    </button>
                                );
                            }
                            return (
                                <div
                                    style={{ display: 'flex', gap: 12 }}
                                    className="rounded-[2px] w-full h-[32px]"
                                >
                                    <Button
                                        onClick={openAccountModal}
                                        type="button"
                                        className={`border border-divBorder text-textColor-hightlightedText hover:bg-buyPlaceOrderBG rounded-[2px] flex justify-center items-center px-[4px] gap-[4px] font-normal text-[14px] focus:outline-none`}
                                        variants="none"
                                        isFullWidth
                                    >
                                        {chain.hasIcon && (
                                            <IoWalletOutline style={{ height: '24px', width: '24px' }} />
                                        )}
                                        <div className="border border-divBorder rounded-[2px] px-2 h-[24px] ">
                                            <div className="text-[14px] ">
                                                {showName === 'true' && account?.displayName}
                                            </div>
                                        </div>
                                        <div className="border border-divBorder rounded-[2px] px-2 h-[24px]">
                                            <div className="flex justify-center items-center text-[14px]">
                                                {formattedBalance > 999 ? (
                                                    <CustomTooltip title={interFormat(formattedBalance, 2)}>
                                                        {shortFormat(formattedBalance)}
                                                    </CustomTooltip>
                                                ) : (
                                                    <div>{truncateToTwoDecimalPlaces(formattedBalance)}</div>
                                                )}
                                                <LabelWethIcon />
                                            </div>
                                        </div>
                                    </Button>
                                </div>
                            );
                        })()}
                    </div>
                );
            }}
        </ConnectButton.Custom>
    );
};
export default ConnectWallet;
