import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { TfiReload } from 'react-icons/tfi';
import LiquidationTableRow from './LiquidationTableRow';
import { useUsdEthPrice, useMarkPrice, useSelectedPage, useItemsPage, useActiveTab, useOrder } from 'store';
import useMarkPriceContractCall from './useMarkPriceContractCall';
import { CustomTooltip, IncDecTriangle } from 'components/Common';
import { useSelectedMarket } from 'store';
import { getTokenLabel } from 'config/marketMapping';

interface LiquidationTablePageProps {
    tableData: any[];
    pageLen: any;
    itemLen: any;
    loading: boolean;
    onLoadMore: (page: any, limit: any, activeTab: any, order: any) => void;
}

// interface Filters {
//     orderBy: boolean;
//     sizeSorted: 0 | 1;
//     sortType: 'currentmr' | 'none';
// }
const LiquidationTablePage: React.FC<LiquidationTablePageProps> = ({
    tableData,
    pageLen,
    loading,
    onLoadMore
}) => {
    const [currentData, setCurrentData] = useState<Array<any>>();
    const { selectedPage, setSelectedPage } = useSelectedPage();
    // eslint-disable-next-line
    const [tooltipTitle, setTooltipTitle] = useState('Refresh to fetch latest data');
    const { usdEthPrice } = useUsdEthPrice();
    const { markPrice, setMarkPrice } = useMarkPrice();
    const { markPrice: contractMarkPrice, refetchMarketPrice }: any = useMarkPriceContractCall();
    const { itemsPerPage, setItemsPerPage } = useItemsPage();
    // const [filters, setFilters] = useState<Filters>({
    //     orderBy: true,
    //     sizeSorted: 0,
    //     sortType: 'none'
    // });
    //const [activeTab, setActiveTab] = useState<'liquidatable' | 'underwater'>('liquidatable');
    const { activeTab, setActiveTab } = useActiveTab();
    const { direction, setOrder } = useOrder();
    useEffect(() => {
        if (!loading && tableData) {
            setCurrentData(tableData);
        }
        // if (filters.sizeSorted != 'NONE') {
        //     //tableData?.sort((a: any, b: any):any => sizeSort(a, b, filters.sizeSorted, filters.sortType));
        //     console.log("sort called",tableData,filters.sizeSorted);
        //     //setCurrentData(tableData);
        // }
    }, [tableData, loading]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (refetchMarketPrice) {
                refetchMarketPrice();
            }
        }, 30_000);

        return () => clearInterval(intervalId);
    }, [refetchMarketPrice]);

    useEffect(() => {
        setMarkPrice(contractMarkPrice || 0);
    }, [contractMarkPrice, setMarkPrice]);

    const handlePageClick = (data: any) => {
        const selectedPage = data.selected;
        console.log('handlePageClick', selectedPage);
        setSelectedPage(data.selected);

        onLoadMore(selectedPage, itemsPerPage, activeTab, direction);
        console.log('handlePageClick after', selectedPage);
    };
    const handleOrderChanged = async () => {
        const selectedOrder = direction === 'desc' ? 'asc' : 'desc';
        console.log('handleOrderChanged', selectedOrder);
        setOrder(selectedOrder);

        onLoadMore(selectedPage, itemsPerPage, activeTab, selectedOrder);
        console.log('handleOrderChanged after', direction);
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            console.log('Interval selectedPage', selectedPage, itemsPerPage, activeTab, direction);
            onLoadMore(selectedPage, itemsPerPage, activeTab, direction);
        }, 5_000);

        return () => clearInterval(intervalId);
    }, [selectedPage, itemsPerPage, activeTab, direction]);

    const handleRefetch = () => {
        console.log('refetch click selectedPage', selectedPage, itemsPerPage);
        onLoadMore(selectedPage, itemsPerPage, activeTab, direction);
    };

    const handleItemsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newItemsPerPage = parseInt(event.target.value, 10);
        console.log('newItemsPerPage', newItemsPerPage);
        setItemsPerPage(newItemsPerPage);
        onLoadMore(selectedPage, newItemsPerPage, activeTab, direction);
    };

    const { selectedMarket } = useSelectedMarket();
    const tokenlabel = getTokenLabel(selectedMarket);

    return (
        <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-textColor-hightlightedText text-[20px] font-medium overflow-hidden w-full">
                <h2 className="text-2xl font-bold mb-4">Liquidation Dashboard</h2>
                <div className="h-[calc(100vh-258px)] border-t border-borderColor overflow-hidden">
                    <div className="p-4 flex justify-between items-center border-b border-borderColor">
                        <div className="flex items-center gap-4">
                            <button
                                onClick={() => setActiveTab('liquidatable')}
                                className={`px-4 py-2 rounded-sm w-[240px] ${
                                    activeTab === 'liquidatable'
                                        ? 'border border-divBorder text-textColor-hightlightedText font-bold'
                                        : 'border border-divBorder text-textColor-unselectedText hover:bg-buyPlaceOrderBG hover:text-textColor-selectedText '
                                }`}
                            >
                                Liquidatable Accounts
                            </button>
                            <button
                                onClick={() => setActiveTab('underwater')}
                                className={`px-4 py-2 rounded-sm w-[240px] ${
                                    activeTab === 'underwater'
                                        ? 'border border-divBorder text-textColor-hightlightedText font-bold'
                                        : 'border border-divBorder text-textColor-unselectedText hover:bg-buyPlaceOrderBG hover:text-textColor-selectedText '
                                }`}
                            >
                                Underwater Accounts
                            </button>
                            <CustomTooltip title={tooltipTitle}>
                                <span
                                    className={`cursor-pointer ${loading ? 'animate-spin' : ''}`}
                                    onClick={handleRefetch}
                                >
                                    <TfiReload className="text-textColor-hightlightedText" />
                                </span>
                            </CustomTooltip>
                        </div>
                        <div className="flex items-center gap-4">
                            {selectedMarket !== 'btc' && (
                                <span className="text-[14px] font-normal text-textColor-hightlightedText">
                                    1 {tokenlabel} = ${usdEthPrice?.toFixed(2) || 'N/A'}
                                </span>
                            )}
                            <span className="text-[14px] font-normal text-textColor-hightlightedText">
                                Mark Price = {markPrice?.toFixed(2) || 'N/A'} {tokenlabel}
                            </span>
                            <select
                                value={itemsPerPage}
                                onChange={handleItemsPerPageChange}
                                className="text-textColor-hightlightedText text-[14px] px-2 py-1 rounded-sm border border-divBorder focus:outline-none focus:ring-2 focus:ring-buyPlaceOrderBG focus:border-transparent"
                            >
                                <option value={10}>10 per page</option>
                                <option value={20}>20 per page</option>
                                <option value={30}>30 per page</option>
                            </select>
                        </div>
                    </div>
                    <div className="overflow-x-auto side-scrollbar">
                        <div className="overflow-y-auto side-scrollbar max-h-[calc(100vh-340px)]">
                            <div className="w-full min-w-[1400px] ">
                                <div className="grid grid-cols-7 text-[18px] py-3 border-b border-borderColor mb-2 text-textColor-acctStatsText">
                                    <div className="text-center">User</div>
                                    <div className="text-center">Position</div>
                                    <div className="text-center">Price</div>
                                    <div className="text-center">Side</div>
                                    <div
                                        className="flex justify-center items-center gap-[3px] cursor-pointer"
                                        onClick={() => {
                                            handleOrderChanged();
                                        }}
                                    >
                                        Current Mr
                                        <IncDecTriangle />
                                    </div>
                                    <div className="text-center">Margin</div>
                                    <div className="text-center">Action</div>
                                </div>
                                <div>
                                    {currentData && currentData.length > 0 ? (
                                        loading ? (
                                            Array.from({ length: itemsPerPage }).map((_, index) => (
                                                <LiquidationTableRow
                                                    key={`loading-${index}`}
                                                    item={null}
                                                    loading={true}
                                                />
                                            ))
                                        ) : (
                                            currentData.map((item) => (
                                                <LiquidationTableRow
                                                    key={item?.id || `item-${item?.user}`}
                                                    item={item}
                                                    loading={false}
                                                />
                                            ))
                                        )
                                    ) : (
                                        <div>
                                            <div className="grid grid-cols-7 text-center py-4 text-gray-500">
                                                {loading ? 'Loading...' : 'No data available'}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {pageLen > 0 && (
                    <div className="flex h-[65px]">
                        <ReactPaginate
                            previousLabel="<"
                            nextLabel=">"
                            onPageChange={handlePageClick}
                            pageCount={pageLen}
                            renderOnZeroPageCount={undefined}
                            containerClassName="pagination-container"
                            pageLinkClassName="pagination-num"
                            previousLinkClassName="pagination-num"
                            nextLinkClassName="pagination-num"
                            activeLinkClassName="pagination-active"
                            forcePage={selectedPage}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default LiquidationTablePage;
