import { useState } from 'react';
import { ReactComponent as XLogo } from 'assets/svg/X logo-Green-Ripple-Gradient.svg';
import { ReactComponent as XTRADING } from 'assets/svg/XTRADING.svg';
import { Dropdown, Menu } from 'antd';
import { RiArrowDropDownFill } from 'react-icons/ri';

const NavbarLeft = () => {
    const [selectedKey, setSelectedKey] = useState(false);
    const selectedChain = /*IS_MAINNET ? 'Polygon Mainnet' : */ 'Base Testnet';
    const handleMenuClick = (key: any) => {
        setSelectedKey(key === 0 ? false : true);
    };

    return (
        <div className="flex items-center h-8">
            <div className="flex gap-4 px-3 mr-2 relative right-[10px] ">
                <div className="flex items-center gap-[4px] justify-center cursor-pointer ">
                    <Dropdown
                        trigger={['click']}
                        placement="bottom"
                        overlay={
                            <Menu
                                selectedKeys={[selectedChain]}
                                onClick={({ key }) => handleMenuClick(key.toString())}
                                className="text-[14px] font-normal text-textColor-navLeftText font-inter w-[198px] relative border border-divBorder px-5 top-[15px]"
                            >
                                {selectedKey === false ? (
                                    <Menu.Item
                                        key="1"
                                        style={{
                                            color: '#AEAFC1',
                                            border: '1px solid #01510a'
                                        }}
                                    >
                                        <a href="https://perp.nftfn.xyz/trade">
                                            <div className="flex items-center text-textColor-hightlightedText">
                                                <span>
                                                    <XLogo style={{ width: '40px', height: '40px' }} />
                                                </span>
                                                <span className="ml-2">Base Mainnet</span>
                                            </div>
                                        </a>
                                    </Menu.Item>
                                ) : (
                                    <Menu.Item
                                        key="0"
                                        style={{
                                            backgroundColor: '#010202',
                                            color: '#AEAFC1',
                                            border: 'none'
                                        }}
                                    >
                                        <a href="https://test.nftfn.xyz">
                                            <div className="flex items-center">
                                                <div className="flex justify-start items-center gap-1 h-[36px] w-[150px]">
                                                    <XLogo style={{ width: '40px' }} />
                                                    <span>
                                                        <XTRADING style={{ width: '100px' }} />
                                                    </span>
                                                </div>
                                                <span>Base Testnet</span>
                                            </div>
                                        </a>
                                    </Menu.Item>
                                )}
                            </Menu>
                        }
                    >
                        <div className="flex justify-center items-center text-textColor-navLeftText">
                            <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                                {selectedKey === false ? (
                                    <div className="flex justify-between items-center">
                                        <div className="flex justify-start items-center gap-1 h-[36px] w-[150px]">
                                            <XLogo style={{ width: '40px' }} />
                                            <span>
                                                <XTRADING style={{ width: '100px' }} />
                                            </span>
                                        </div>
                                        <span>Base Testnet</span>
                                    </div>
                                ) : (
                                    <div className="flex justify-between items-center">
                                        <div className="flex justify-start items-center gap-1 h-[36px] w-[150px]">
                                            <XLogo style={{ width: '40px' }} />
                                            <span>
                                                <XTRADING style={{ width: '100px' }} />
                                            </span>
                                        </div>
                                        <span>Base Mainnet</span>
                                    </div>
                                )}
                            </a>
                            <span>
                                <RiArrowDropDownFill style={{ height: '30px', width: '30px' }} />
                            </span>
                        </div>
                    </Dropdown>
                </div>
            </div>
        </div>
    );
};

export default NavbarLeft;
