import React from 'react';
import { useSelectedMarket } from 'store';

const LabelSnvIcon = () => {
    const { selectedMarket } = useSelectedMarket();
    return (
        <span className="text-[10px] flex items-center tracking-wider font-normal font-inter h-[16px] bg-snvBackground text-textColor-snvText px-[4px] pt-[1px] pb-[1.7px] rounded-sm">
            {selectedMarket.toUpperCase()}
        </span>
    );
};

export default LabelSnvIcon;
