import React from 'react';
import { LoaderSizeMarginProps } from '../../interfaces/Loaders';
import { createAnimation } from './helper';

//eslint-disable-next-line
const beat = createAnimation(
    'BeatLoader',
    '50% {transform: scale(0.75);opacity: 0.2} 100% {transform: scale(1);opacity: 1}',
    'beat'
);

export interface BeatLoaderProps extends LoaderSizeMarginProps {
    /**
     * isLoading {boolean} to display loader, default to true
     */
    isLoading?: boolean;
    /**
     * color {string}
     */
    color?: string;
    /**
     * speedMultiplier {number}
     */
    speedMultiplier?: number;
}

const BeatLoader: React.FC<BeatLoaderProps> = ({
    isLoading = true,
    //eslint-disable-next-line
    color = 'white',
    //eslint-disable-next-line
    speedMultiplier = 1,
    //eslint-disable-next-line
    cssOverride = {},
    size = 16,
    //eslint-disable-next-line
    margin = 2,
    //eslint-disable-next-line
    ...props
}) => {
    if (!isLoading) {
        return null;
    }

    return (
        <span className="flex justify-center items-center">
            <div
                className={`loading-dots ${size === 'sm' ? 'my-[-5px]' : 'my-[8.5px]'}`}
                id="first-dot"
            ></div>
            <div
                className={`loading-dots ${size === 'sm' ? 'my-[-5px]' : 'my-[8.5px]'}`}
                id="second-dot"
            ></div>
            <div
                className={`loading-dots ${size === 'sm' ? 'my-[-5px]' : 'my-[8.5px]'}`}
                id="third-dot"
            ></div>
        </span>
    );
};

export default BeatLoader;
