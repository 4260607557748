import { useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import clsx from 'clsx';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as PortfolioActive } from 'assets/svg/LM-3rd-logo-active.svg';
import { ReactComponent as PortfolioInActive } from 'assets/svg/LM-3rd-logo-blur.svg';
import { useScreenWidthContext } from 'context';
import { useSelectedMarket } from 'store';

export const navItems = [
    {
        id: nanoid(),
        label: 'Liquidation Dashboard',
        toLink: 'details',
        activeIcon: PortfolioActive,
        inActiveIcon: PortfolioInActive
    }
];

const LiquidationDashboardPage = () => {
    const { selectedMarket } = useSelectedMarket();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [activeLink, setActiveLink] = useState(0);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [hoverProp, setHoverProp] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const width = useScreenWidthContext();

    const activeLinkHandler = (idx: number) => {
        setActiveLink(idx);
    };

    useEffect(() => {
        switch (location.pathname) {
            case '/dashboard':
                navigate('/dashboard');
                break;
            case `/dashboard/${selectedMarket}/details`:
                navigate(`/dashboard/${selectedMarket}`);
                setActiveLink(0);
                break;
        }
    }, [location.pathname, width]);

    return (
        <>
            {
                <div className={`flex h-full`}>
                    <div
                        className={clsx(
                            isOpen ? 'sidebar-open' : 'sidebar-close',
                            ` bg-backgroundColor border border-borderColor mt-[-1px] flex flex-col gap-3 ${'h-[calc(100vh-59px)]'}`
                        )}
                        onMouseOver={() => setIsOpen(true)}
                        onMouseLeave={() => setIsOpen(false)}
                    >
                        {navItems.map((item, idx) => (
                            <NavLink key={item.id} to={item.toLink} onClick={() => activeLinkHandler(idx)}>
                                <div
                                    className={`${
                                        activeLink === idx && 'active-navlink-bg'
                                    } flex gap-3 h-[2.8rem] transition-all items-center pl-[15px] mt-[4px] max-w-[190px] min-w-[100px] overflow-hidden`}
                                    onMouseOver={() => setHoverProp(true)}
                                    onMouseLeave={() => setHoverProp(false)}
                                >
                                    {activeLink === idx ? (
                                        <span>
                                            {<item.activeIcon style={{ height: '30px', width: '30px' }} />}
                                        </span>
                                    ) : (
                                        <span>
                                            {<item.inActiveIcon style={{ height: '30px', width: '30px' }} />}
                                        </span>
                                    )}
                                    {isOpen && (
                                        <span
                                            className={
                                                activeLink === idx
                                                    ? 'text-textColor-hightlightedText text-[12px] max-w-[140px] min-w-[140px] overflow-hidden font-semibold product-name '
                                                    : 'text-[14px] font-medium product-name max-w-[130px] min-w-[130px] overflow-hidden text-textColor-unselectedText '
                                            }
                                        >
                                            {item.label}
                                        </span>
                                    )}
                                </div>
                            </NavLink>
                        ))}
                    </div>
                    <div
                        className={clsx(
                            isOpen ? 'sidebar-open-outlet' : 'sidebar-close-outlet',
                            `bg-backgroundColor h-full`
                        )}
                    >
                        <Outlet />
                    </div>
                </div>
            }
        </>
    );
};
export default LiquidationDashboardPage;
