import { ConnectWalletButton } from 'components/Common';
import { useState } from 'react';

const NavbarRight = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

    return (
        <>
            <div className="flex items-center h-8">
                <div
                    className="h-6 px-4 flex items-center ml-4 cursor-pointer"
                    onClick={() => {
                        setIsDropdownOpen(!isDropdownOpen);
                    }}
                ></div>

                <div className="ml-3">
                    <ConnectWalletButton showName="true" />
                </div>
            </div>
        </>
    );
};

export default NavbarRight;
